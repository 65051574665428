.foundationBaseInfoModal {
    display: flex;
    align-items: center;

    &__toggle {
        color: color(text, base) !important;
        background-color: color(contrast, white) !important;
        border-color: color(darkContrast, 2) !important;
        font-size: set-fontSize(14) !important;

        &:hover, &:active, &.is-active {
            color: color(contrast, white) !important;
            background-color: color(darkContrast, 2) !important;
            border-color: color(darkContrast, 2) !important;
        }
        
        @include breakpoint(md) {
            width: auto;
        }
    }

    &__section {
        border-bottom: 1px solid color(contrast, 3);
        overflow: visible;
    
        &:last-of-type {
            padding-top: spacer(xl);
            border-bottom: none;
        }
    }

    &__introContainer {
        flex-wrap: wrap;
        text-align: center;
        
        @include breakpoint(md) {
            padding: 0 spacer(2xl);
            text-align: left;

            &:first-of-type {
                margin-bottom: spacer(base);
            }
        }

        &--rightFoundation {
            @include breakpoint(md) {
                padding: 0 0 spacer(base);
            }
        }
    }

    &__addOn {
        flex-wrap: wrap;
        flex: 1 0 100%;
        justify-content: center;
        margin: spacer(2xs) 0 spacer(sm);

        @include breakpoint(md) {
            flex-wrap: nowrap;
            order: 2;
            margin-top: spacer(md);
        }
    }

    &__intro,
    &__addOnPrice {
        flex: 1 0 100%;

        @include breakpoint(md) {
            flex: 0 0 375px;
            margin-left: spacer(2xl);
        }

        &--rightFoundation {
            @include breakpoint(md) {
                flex: 0 0 400px;
            }
        }
    }

    &__addOnToggle {
        flex: 1 0 100%;
        max-width: 300px;
        margin-bottom: spacer(base);

        @include breakpoint(md) {
            flex: 1 0 auto;
            margin-bottom: 0;
        }
    }
    

    &__intro {
        order: 2;
        margin-bottom: spacer(2xl);
        line-height: 1.63;

        @include breakpoint(md) {
            order: 1;
            margin-bottom: 0;
        }

        &--rightFoundation {
            margin-top: spacer(xl);
            
            @include breakpoint(md) {
                margin-top: 0;
            }
        }
    }



    &__imageContainer {
        flex: 1 0 auto;
        text-align: center;
    }

    &__image {
        max-width: 200px;

        &--rightFoundation {
            @include breakpoint(md, max) {
                width: 150px;
            }
        }

        @include breakpoint(md) {
            max-width: 158px;
        }
    }
}
