@import './component-base.styles.scss'; // may need to be imported per component file if not put here globally
@import '../components/DropdownPanel/DropdownPanel.scss';
@import '../components/LegacyPicture/LegacyPicture.scss';
@import '../components/FoundationHeightsModal/FoundationHeightsModal.scss';
@import '../components/TabbedContentDisplay/TabbedContentDisplay.scss';
@import '../components/MattressFaceOff/MattressFaceOff.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/FoundationBaseAddon.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/partials/AddonToggle/AddonToggle.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/partials/FoundationBaseInfoModal/FoundationBaseInfoModal.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/partials/IncludedAddonList/IncludedAddonList.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/partials/PriceSnapShot/PriceSnapShot.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/partials/ProductConfigLabel/ProductConfigLabel.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/partials/RelatedProductDropdownPanelAddonTile/RelatedProductDropdownPanelAddonTile.scss';
@import '../components/StrikethroughPrice/StrikethroughPrice.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/partials/TabList/TabList.scss';
@import '../components/Addon/Addons/FoundationBaseAddon/partials/ProductSelectToggle/ProductSelectToggle.scss';
@import '../components/EpigraphModelViewer/EpigraphModelViewer.scss';
@import '../components/Addon/Addons/FoundationDropdownAddon/FoundationDropdownAddon.scss';
@import '../components/Addon/Addons/FoundationDropdownAddon/partials/FancyBanner/FancyBanner.scss';
@import '../components/Addon/Addons/FoundationDropdownAddon/partials/DiscountBadge/DiscountBadge.scss';
@import '../components/Addon/Addons/DropdownBuystackAddon/DropdownBuystackAddon.scss';
@import '../components/FoundationModal/FoundationModal.scss';
@import '../components/FeaturesSlider/FeaturesSlider.scss';
@import '../components/FeaturesSlider/FlickitySlider/FlickitySlider.scss';
@import '../components/FeaturesSlider/TabCarousel/TabCarousel.scss';
@import '../components/RequestSwatches/RequestSwatches.scss';

/* BEGIN global style overrides */
/* Fixes Safari mobile default button color of blue */
button {
    color: color(text, base);
}

/* reduces CLS by ensuring the space is always reserved */
/* included here in the global namespace because it needs to be referenced exactly, not via a module name */ 
.pdpVideoLazyLoadContainer-wrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; 
}

.carouselVideoLazyLoadContainer-wrapper {
    height: inherit;
}